import React from "react"
import AccessoryInfo from "../AccessoryInfo"

type Props = {
  id: number
  name: string
  microphone_type: string | null | string[]
  active_gard: string | null | string[]
  wearing_style: string | null | string[]
  image_url: string
  onSelect?: (id: number) => void
  series?: boolean
  className?: string
  description?: string
}

const HeadsetCard = (props: Props) => {
  const {
    id,
    name,
    microphone_type,
    active_gard,
    wearing_style,
    image_url,
    onSelect,
    series,
    className,
    description,
  } = props

  const transformWearingStyle = (wearing_style: string | string[] | null) => {
    if (!wearing_style || wearing_style.length === 0) return "-"

    if (Array.isArray(wearing_style)) {
      if (wearing_style.length === 2) return "Single sided | Double sided"
      return wearing_style.includes("single_sided")
        ? "Single sided"
        : "Double sided"
    }

    return wearing_style === "single_sided" ? "Single sided" : "Double sided"
  }

  const transformMicrophoneName = (
    microphone_type: string | string[] | null,
  ) => {
    if (!microphone_type || microphone_type.length === 0) return "-"
    if (typeof microphone_type === "string")
      return microphone_type[0].toUpperCase() + microphone_type.slice(1)

    return microphone_type
      .map((microphone) => microphone[0].toUpperCase() + microphone.slice(1))
      .join(" | ")
  }

  const transformActiveGuard = (active_gard: string | string[] | null) => {
    if (!active_gard || active_gard.length === 0) return "-"
    if (active_gard === "both")
      return "With ActiveGard™  |  Without ActiveGard™"
    if (active_gard === "only_with") return "With ActiveGard™"
    if (active_gard === "only_without") return "Without ActiveGard™"

    if (Array.isArray(active_gard)) {
      if (active_gard.includes("Both")) {
        return "With ActiveGard™  |  Without ActiveGard™"
      } else {
        return active_gard.join(" | ")
      }
    }
    return "-"
  }

  const listOfAccessories = [
    {
      accessoryType: "Microphone",
      accessoryName: transformMicrophoneName(microphone_type),
    },
    {
      accessoryType: "ActiveGuard",
      accessoryName: transformActiveGuard(active_gard),
    },
    {
      accessoryType: "Headset Type",
      accessoryName: transformWearingStyle(wearing_style),
    },
  ]

  return (
    <article
      className={`group flex flex-col w-full border-b-2 border-slate-100 ${className}`}
      onClick={onSelect ? () => onSelect(id) : undefined}
    >
      <div className="relative flex justify-center h-96 bg-primaryGray">
        {onSelect !== undefined && (
          <div className="group-hover:bg-hoverGreen opacity-80 absolute top-0 left-0 h-full w-full flex justify-center items-center">
            <p className="opacity-0 group-hover:opacity-100 text-white">
              Choose and continue
            </p>
          </div>
        )}
        <img src={image_url} alt={name} className="p-2 max-h-full" />
      </div>
      <div className="py-5 lg:py-10">
        <p className="font-sans text-lg font-semibold mb-2 lg:mb-4">
          {series ? `${name} Series` : name}
        </p>
        {!series && (
          <pre className="font-sans mb-6 lg:mb-12 text-sm font-light">
            {description}
          </pre>
        )}
        <article className="flex flex-col gap-2 text-sm">
          {listOfAccessories.map((accessory) => (
            <AccessoryInfo {...accessory} key={id + accessory.accessoryType} />
          ))}
        </article>
      </div>
    </article>
  )
}

export default HeadsetCard
